<template>
    <div>
        <v-container fluid>

             <v-layout align-center justify-center v-if="loadingCADRequestMaterials">
                <a-spin class="mt-2" tip="Loading..." />
            </v-layout>

            <v-row>
                <v-col xs="6" sm="6" md="6" lg="6">
                     <a-input
                        v-model="strItem"
                        addon-before="Search"
                    ></a-input>
                </v-col>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                    <a-button
                        v-on="on"
                        icon="plus"
                        style="background-color: #0f3057; color: white"
                        @click="openRequestDialog()"
                        class="mt-3"
                        >New</a-button
                    >
                    </template>
                    <span>New User</span>
                </v-tooltip>
            </v-row>

            <v-row>
                <v-col xs="8" sm="8" md="8" lg="8">
                    <v-simple-table class="main-table">
                        <template v-slot:default>
                            <thead>
                                <tr width="100%">
                                    <th colspan="3" class="pa-3"> 
                                        <h1 style="font-family:Arial;color:black;font-weight:bold" class="text-center"> 設備シート依頼用入力シート  </h1>
                                    </th>
                                </tr>
                                <tr>
                                    <th colspan="3">
                                        <h3 style="font-family:Arial;color:black" class="text-center">  必要なページを印刷し、チェックボックスに記載をして、他資料と併せてCAD依頼を行って下さい。  </h3>
                                    </th>
                                </tr>
                                <tr>
                                    <th colspan="3">
                                         <h3 style="font-family:Arial;color:black" class="text-right">  最終更新：2021/12/14  </h3>  
                                    </th>
                                </tr>
                            </thead>
                            <template v-for="(data, index) in filteredRequestMaterials">
                                <tbody :key="index">                               
                                    <tr v-for="(rec,a) in data.subItems" :key="a">
                                      <!-- <td  v-if="index == 0" style="font-family:Arial" :rowspan="requestMaterials.length" >    -->
                                      <td v-if="a == 0" class="text-center" :rowspan="data.subItems.length" >  
                                         <span style="font-family:Arial;font-weight:bold" class="text-center">  {{ data.item }} </span>
                                      </td>
                                      <td>     
                                         <!-- @click="openURL_CADRequestMaterials(rec, data.id )"                                -->
                                         <span style="font-family:Arial;color:#1C3879;cursor:pointer" class="text-center" >  {{ rec.filename  }}  </span>                                   
                                      </td>    
                                      <td v-if="a == 0" class="text-center" :rowspan="data.subItems.length">
                                         <v-tooltip bottom transition="scroll-y-transition">
                                            <template v-slot:activator="{on}">
                                                <v-btn @click="editRequestMaterials(data)" v-on="on" small class="primary mr-2" style="font-family:Arial"> 
                                                    <v-icon> mdi-pencil</v-icon>
                                                </v-btn>
                                            </template>
                                          <span style="font-family:Arial">  Edit </span>
                                         </v-tooltip>

                                         <v-tooltip bottom transition="scroll-y-transition">
                                            <template v-slot:activator="{on}">
                                                <v-btn @click="deleteRequestMaterials(data)" v-on="on" small class="error" style="font-family: Arial"> 
                                                    <v-icon> mdi-delete </v-icon>
                                                </v-btn>
                                            </template>
                                            <span style="font-family:Arial"> Delete </span>
                                         </v-tooltip>
                                         
                                      </td>
                                    </tr>
                                </tbody>
                            </template>                      
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>

            <!-- Update Item -->
            <v-dialog max-width="800" v-model="updateRequestDialog" persistent scrollable >
              <v-card>
                <!-- <pre> {{ toAdd }} </pre> -->
                <v-toolbar dense flat style="background-color: #f6f5f5">
                    <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>
                <v-spacer />
                 <v-icon @click="closeEditRequestMaterials()">mdi-close</v-icon>
                </v-toolbar>
                <v-card-text>
                    <v-layout class="pa-1 ma-1">
                        <a-input
                        addon-before="*Item Name"
                        v-model="toAdd.item"
                        style="width: 100%"
                        class="mr-1"
                        ></a-input>
                    </v-layout>
                    <v-divider class="mt-3 mb-3"/>
                    <v-layout class="pa-1 ma-1">
                        <a-input
                        addon-before="SubItem"
                        v-model="toAdd.filename"
                        style="width: 50%"
                        class="mr-1"
                        ></a-input>
                        <a-select
                            v-model="toAdd.extension"
                            addon-before="Extension"
                            style="width: 30%"
                        >
                    
                            <a-select-option
                            v-for="item in extensionArr"
                            :key="item"
                            v-value="item"
                            >{{ item }}</a-select-option>
                        </a-select>

                        <a-button
                            block
                            icon="down"
                            style="background-color: #0f3057; color: white;width:20%"
                            :disabled="!toAdd.filename && !toAdd.extension"
                            @click="pushNewSubItem()"
                        > Add </a-button >
                    </v-layout>
                    <v-row>
                        <v-col xs="12" sm="12" md="12" lg="12">
                             Total: {{ toAdd.subItems.length }}
                             <v-simple-table class="main-table">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="pa-3"> Filename </th>
                                            <th class="pa-3"> Extension </th>
                                            <th class="pa-3"> Actions </th>
                                        </tr>                             
                                    </thead>
                                    <tbody >                               
                                        <tr v-for="(item, i) in toAdd.subItems" :key="i">
                                            <td> {{ item.filename }} </td>
                                            <td> {{ item.extension }} </td>
                                            <td>
                                                <v-tooltip bottom transition="scroll-y-transition">
                                                    <template v-slot:activator="{on}">
                                                        <v-btn @click="openUploadDialog(item)" v-on="on" x-small class="success"> 
                                                            <v-icon>mdi-upload</v-icon>
                                                        </v-btn> 
                                                    </template>
                                                   <span style="font-family:Arial"> Upload file</span>
                                                </v-tooltip>
                                                
                                                <v-tooltip bottom transition="scroll-y-transition">
                                                    <template v-slot:activator="{on}">
                                                        <v-btn @click="removeSubItem(i)" v-on="on" x-small class="error">
                                                            <v-icon> mdi-delete</v-icon>
                                                        </v-btn>
                                                    </template>
                                                  <span style="font-family:Arial"> Remove </span>
                                                </v-tooltip>
                                            </td>
                                        </tr>
                                    </tbody>               
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                   
                </v-card-text>
                <v-card-actions>
                     <a-button
                        block
                        icon="save"
                        style="background-color: #0f3057; color: white;width:100%"
                        @click="UpdateRequestMaterials()"
                    > Update </a-button >
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- Add Item -->
            <v-dialog max-width="600" v-model="requestDialog" persistent>
                <v-card>
                    <v-toolbar dense flat style="background-color: #f6f5f5">
                    <v-toolbar-title>{{ dialogTitle }}</v-toolbar-title>
                    <v-spacer />
                    <v-icon @click="closeRequestDialog()">mdi-close</v-icon>
                    </v-toolbar>
                    <v-card class="ma-1 pa-1">
                    <!-- {{editIndex}} -->
                    <v-layout class="pa-1 ma-1">
                        <a-input
                        addon-before="*Item Name"
                        v-model="toAdd.item"
                        style="width: 100%"
                        class="mr-1"
                        ></a-input>
                    </v-layout>
                    <v-divider/>
                    <v-layout class="pa-1 ma-1">
                        <v-spacer />
                        <a-button
                        block
                        icon="save"
                        style="background-color: #0f3057; color: white"
                        @click="SaveCADRequestMaterial()"
                        >Save</a-button >
                    </v-layout>
                    </v-card>
                </v-card>
             </v-dialog>

             <!-- Upload file Dialog-->
             <v-dialog max-width="600" v-model="uploadFileDialog" persistent>
                <v-card>
                     <v-toolbar dense flat style="background-color: #f6f5f5">
                        <v-toolbar-title> Upload File </v-toolbar-title>
                        <v-spacer />
                        <v-icon @click="closeUploadDialog()">mdi-close</v-icon>
                    </v-toolbar>
                    <v-card-text>
                        <v-container fluid>
                            <!-- {{objFileUpload}} -->
                           <!-- <pre>  {{ toAdd }} </pre> -->
                             <file-pond 
                                credits="false"
                                name="test"
                                ref="pond"
                                label-idle="Drag or Click to add file"
                                v-model="uploadFiles"
                                :onaddfile="handleFilePondInit"
                                accepted-file-types="application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                             />
                        </v-container>
                    </v-card-text>
                    <v-divider/>
                    <v-card-actions>        
                        <a-button
                        @click="uploadFile()"
                        block
                        icon="upload"
                        style="background-color: #0f3057; color: white"
                        :loading="isFileUploading"
                        > Upload File </a-button >
                    </v-card-actions>
                </v-card>
             </v-dialog>

        </v-container>
    </div>
</template>
<script>
import axios from 'axios'
import _ from 'lodash'
import AWS from 'aws-sdk'
import tunnel from 'tunnel'

import moment from 'moment'

// Import Vue FilePond
import vueFilePond from 'vue-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import FilePond plugins
// Please note that you need to install these plugins separately

// Import image preview plugin styles
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'

// Import image preview and file type validation plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
)

export default {
     components:{
        FilePond   // 2022-06-30
    },
    data(){
        return {
            loadingCADRequestMaterials: false,
            requestMaterials: [],
            strItem: "",
            dialogTitle: "Add",
            toAdd: {
                item: null,
                subItems: []
            },
            requestDialog: false,
            updateRequestDialog: false,
            uploadFileDialog: false,
            maxSequence: null,
            extensionArr: ['pdf', 'xls', 'xlsx'],
            objFileUpload: {},

            uploadFiles: [],
            isFileUploading: false,

            filenameToUpload: ""  // 2023-03-20
        }
    },
    watch: {
        uploadFileDialog(val) {
            //  console.log('line322', val )
            //  console.log('line323', this.objFileUpload )
             if( val ) {

                  let index = this.toAdd.subItems.findIndex( rec =>{
                     return rec.filename == this.objFileUpload.filename
                  })

                  this.toAdd.subItems[index].filename_attachment = `${this.objFileUpload.filename}_${moment().format("YYYYMMDDHHmmss")}`;
                  this.filenameToUpload =  `${this.objFileUpload.filename}_${moment().format("YYYYMMDDHHmmss")}`;  // filename to upload
             }
        }
    }, 
    created(){
        this.GetAllCADRequest();
    },
    computed:{
        filteredRequestMaterials() {
            let data = []
            if( this.strItem ) {
                data = this.requestMaterials.filter(rec=>{
                    return rec.item.toLowerCase().includes( this.strItem.toLowerCase() )
                })
            }else{
                data = this.requestMaterials
            }
            return data
        }
    },
    methods: {
    
         async GetAllCADRequest(){
            try{
                this.loadingCADRequestMaterials = true
                let url = `${this.api}cadRequestMaterials/getlAllCADRequest`
                let response = await axios.get(url)
                //  =================== CAD Request Materials ============= // 

                this.requestMaterials  = response.data
                // .filter(rec=>{
                //      return rec.item != '-'
                // })
                .sort( (a,b) => {
                     return a.sequence - b.sequence
                })

                this.maxSequence = _.max(_.map( this.requestMaterials, 'sequence'))
                console.log('lin145', this.maxSequence )
                console.log('line146', this.requestMaterials)
                
                this.loadingCADRequestMaterials = false

            }catch(err){
                alert('Error Fetching Records, Please try again..');
                this.loadingCADRequestMaterials = false
                console.log(err.message)
            }
        },
        SaveCADRequestMaterial(){
            if( this.dialogTitle == 'Add') {
                 console.log('add')
                 console.log('nextSequence', this.maxSequence++)
                 const url = `${this.api}cadRequestMaterials/InsertNewCADRequestMaterials`
                 let toSend = {
                    item: this.toAdd.item,
                    sequence: this.maxSequence++,
                    subItems: [
                         { "filename": null , "extension": null  , "filename_attachment": null }  // 2023-03-20
                    ]
                 }
                 axios.post( url, toSend ).then(res=>{
                     if( res.data ) {
                        alert('New Item Added')
                        this.GetAllCADRequest();
                        this.closeRequestDialog();
                     }
                 }).catch(err=>{
                    console.log(err.message)
                    alert('Error Saving')
                    this.closeRequestDialog();
                 })
            }
        },
        openRequestDialog(){
            console.log('open request dialog');
            this.requestDialog = !this.requestDialog
        },
        closeRequestDialog(){
             this.dialogTitle = 'Add'
             this.toAdd = {
                item: null,
                subItems: []
             }
             this.requestDialog = !this.requestDialog
        },
        editRequestMaterials( data ) {
             this.dialogTitle = 'Update'
             this.toAdd = data
             this.updateRequestDialog = !this.updateRequestDialog
        },
        closeEditRequestMaterials() {
             this.dialogTitle = 'Add'
              this.GetAllCADRequest();
             this.updateRequestDialog = !this.updateRequestDialog
        },
        pushNewSubItem(){
            console.log('push new sub item')
            let toPush = {
                filename: this.toAdd.filename,
                extension: this.toAdd.extension
            }
            if( this.toAdd.filename && this.toAdd.extension) {
                 this.toAdd.subItems.push(toPush)
            } else{
                alert('Empty Field(s)')
            }
           
            this.toAdd.filename = null 
            this.toAdd.extension = null
        },
        UpdateRequestMaterials(){
            if( this.dialogTitle == 'Update') {

                const url = `${this.api}cadRequestMaterials/UpdateCADRequestMaterials`
                let toUpdate = {
                    id: this.toAdd.id,
                    item: this.toAdd.item,
                    subItems: this.toAdd.subItems,
                    UpdatedBy: this.userInfo.name
                }
                axios.put( url, toUpdate ).then(res=>{
                    if( res.data ) {
                        alert('Update Successfully')
                        this.closeEditRequestMaterials();
                    }
                })
                .catch(err=>{
                    alert('Error to Update');
                    console.log(err.message)
                })
            } 
        
        },
       UpdateRequestMaterials_NoCloseForm(){  // 2023-03-20
            if( this.dialogTitle == 'Update') {

                const url = `${this.api}cadRequestMaterials/UpdateCADRequestMaterials`
                let toUpdate = {
                    id: this.toAdd.id,
                    item: this.toAdd.item,
                    subItems: this.toAdd.subItems,
                    UpdatedBy: this.userInfo.name
                }
                axios.put( url, toUpdate ).then(res=>{
                    if( res.data ) {
                        alert('Update & Update Successfully')
                    }
                })
                .catch(err=>{
                    alert('Error to Update');
                    console.log(err.message)
                })
            } 
        },

        removeSubItem( index) {
            this.toAdd.subItems.splice( index, 1 )
        },


        handleFilePondInit(){
          return new Promise((resolve,reject) => {
           
            let toResolve = []
            if( this.uploadFiles != undefined ) {
                toResolve = this.uploadFiles.map(rec=>{ 
                    return rec.file
                })
                resolve(toResolve)
            } else{
                reject([])
            }     
            })
        },
        openUploadDialog( data) {            
            this.objFileUpload = data

            this.uploadFileDialog = !this.uploadFileDialog
        },
        closeUploadDialog(){
            this.objFileUpload = {}
            this.uploadFiles = []
            this.$refs.pond.removeFiles();  // 2022-11-17

            this.filenameToUpload= ""  // 2023-03-17

            this.uploadFileDialog = !this.uploadFileDialog
        },

         promiseUploadFiles( files ){
            let count= 0;
            return new Promise((resolve,reject) => {
            if( files.length > 0 ) {
                this.isFileUploading = true
                var tunnelingAgent = tunnel.httpsOverHttp({
                    proxy: {
                        // Proxy settings
                        host: "hrdproxy.hrd-s.com",
                        port: 81,
                        proxyAuth: "administrator:system",
                    },
                });

                AWS.config.update(
                {
                    httpOptions: { agent: tunnelingAgent },
                    region: 'us-east-2', 
                    accessKeyId: this.accessKeyId,
                    secretAccessKey: this.secretAccessKey
                });

                let s3 = new AWS.S3({apiVersion: '2006-03-01'});

                let self = this; // 2023-03-20
            
                for( let i =0; i< files.length; i++) {

                  
                    // let tmpFilenName = `${this.objFileUpload.filename}.${this.objFileUpload.extension}`  // filename_20230317112520.pdf
                    
                    let tmpFilenName = `${this.filenameToUpload}.${this.objFileUpload.extension}`  // filename_20230317112520.pdf 2023-03-20

                    // let self = this;
                    let uploadParams = {
                        Bucket: 'rulebook.files',
                        Key: `CAD依頼資料/${tmpFilenName}`,
                        Body: files[i]
                    };

                    s3.upload (uploadParams, function (err, data) {
                        if (err) {
                            console.log("Error", err);
                            reject(err)
                        } if (data) {
                            // console.log("Upload Success", data.Location);
                            count++
                           
                            if( count == files.length ) {                              
                                resolve('Upload Success')
                                self.UpdateRequestMaterials_NoCloseForm()
                                console.log('Upload Sucess')
                            }
                            
                        }
                    });
                }
            }else{
                reject({ message: 'No such files to upload' })
            }
            })
        },
        async uploadFile() {
            try{
                let files = await this.handleFilePondInit();
                await this.promiseUploadFiles( files );
                alert('Upload Success');
                this.isFileUploading = false
                this.closeUploadDialog();
            }catch(err) {   
                console.log('line593',err.message);
                alert(err.message);
            }
        },
        deleteRequestMaterials( data ) {
            console.log( data )
            if( confirm(`Are you sure want to delete ${data.item} ?  `)) {
                const url = `${this.api}cadRequestMaterials/DeleteCADRequestMaterials/${data.id}`
                axios.delete(url).then(res=>{
                    if( res.data ) {
                        alert('Successfully Deleted!');
                        this.GetAllCADRequest();
                    }
                })
                .catch(err=>{
                    console.log(err.message)
                })
            }
        }

    }
}
</script>

<style scoped>


table, td , th {
  border: 1px solid gray !important;
  border-collapse: collapse !important;
  margin:2px;
}

.v-application .text-center {
    text-align: center !important;
    margin-bottom: -5px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.875rem ;
    height: 30px ;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
    height: 30px;
    padding-top: 5px;
    padding-bottom: 1px;
}

 .v-application--is-ltr .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-application--is-ltr .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    text-align: left;
    padding-top: 5px;
    padding-bottom: 5px;
    height: 40px;
}



</style>